import React, { useState } from 'react';
import { Link } from 'gatsby';

const SubscriptionForm = ({ darkBackground }) => {
  const [status, setStatus] = useState(null);
  const [consented, setConsented] = useState(false);
  const [email, setEmail] = useState('');

  const FORM_URL = 'https://app.convertkit.com/forms/1919449/subscriptions';

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    try {
      const response = await fetch(
        FORM_URL,
        {
          method: 'post',
          body: data,
          headers: {
            accept: 'application/json',
          },
        },
      );
      setEmail('');
      const json = await response.json();
      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }

      setStatus('ERROR');
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (status === 'ERROR') {
      setStatus(null);
    }
  };

  const borderColor = darkBackground ? 'border-white' : 'border';
  const textColor = darkBackground ? 'text-white' : '';
  const lightTextColor = darkBackground ? 'text-green-200' : 'text-gray-500';

  return (
    <div>
      {status === 'SUCCESS' && <p className="mb-2 text-white">Thanks for signing up!</p>}
      {status === 'ERROR' && <p className="mb-2 text-white">Oops, something went wrong! Try again.</p>}

      <form className="sm:flex" action={FORM_URL} method="post" onSubmit={handleSubmit}>
        <label for="email_address" className="sr-only">Email address</label>
        <input
          id="email_address"
          name="email_address"
          type="email"
          autocomplete="email"
          required
          className={`w-full ${borderColor} px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-700 focus:ring-white rounded-md`}
          placeholder="Enter your email"
          onChange={handleInputChange}
          value={email}
        />
        <button
          disabled={!consented}
          type="submit"
          className={'mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0 disabled:opacity-50 disabled:cursor-not-allowed'}
        >
          Sign up
        </button>
      </form>
      <label className="inline-flex items-center mt-3">
        <input
          className="form-checkbox h-4 w-4 text-green-600 rounded"
          id="consent"
          name="consent"
          type="checkbox"
          required
          onChange={(e) => {
            setConsented(e.target.checked);
          }}
          checked={consented}
        />
        <span className="ml-2">
          <p className={`text-sm ${lightTextColor}`}>
            I&apos;ve read and agree to the
            <Link to="/privacy" className={`${textColor} font-medium underline ml-1`}>
              Privacy Policy.
            </Link>
          </p>
        </span>
      </label>
      <p className={`mt-3 text-sm ${lightTextColor}`}>
        I won&apos;t send you spam and you can unsubscribe at any time.
      </p>
    </div>
  );
};

export default SubscriptionForm;
